/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "styles/pages/competition-teaser.scss"

import React from "react"
import Slider from "react-slick"

import Layout from "components/layout"
import Seo from "components/seo"

const CompetitionTeaser = () => {
  const slider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  const sliderData = [
    require("assets/images/competition-bg-image-1.jpg").default,
    require("assets/images/competition-bg-image-2.jpg").default,
  ]

  return (
    <Layout isContest>
      <Seo title="Competition Teaser" />
      <section className="competition-teaser">
        <Slider {...slider}>
          {sliderData?.map((item, index) => (
            <div key={index}>
              <div
                className="competition-teaser__slide"
                style={{ backgroundImage: `url('${item}')` }}
              ></div>
            </div>
          ))}
        </Slider>

        <div className="container-fluid">
          <img
            className="img-fluid competition-teaser__logo"
            src={require("assets/images/competition-teaser-header.png").default}
            alt=""
          />
          <h1 className="competition-teaser__header">
            Kappingin
            <br /> byrjar 1. desember
          </h1>
          <p className="competition-teaser__description">
            Fyri at luttaka í kappingini mást
            <br />
            tú skanna QR-kotuna á mjólkarpakkanum.
          </p>
        </div>

        <h4 className="competition-teaser__info">
          Í samstarvi við <span>Atlantic Airways</span>
        </h4>
      </section>
    </Layout>
  )
}

export default CompetitionTeaser
